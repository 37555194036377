<template lang="html">
    <div
        class="Download"
        :class="{ 'is-disabled': disabled, 'is-small': size === 'small' }"
        @click="download"
    >
        <span>{{ title }}</span>
        <IconBase :fill="svgFill" stroke="none">
            <IconPDF v-if="type === 'pdf'" />
            <IconCSV v-else />
        </IconBase>
    </div>
</template>

<script>
import IconBase from '../icons/IconBase';
import IconPDF from '../icons/IconPDF';
import IconCSV from '../icons/IconCSV';

export default {
    props: {
        size: {
            type: String,
            default: 'medium',
        },
        title: String,
        type: String,
        disabled: Boolean,
        onDownload: {
            type: Function,
            default: () => ({}),
        },
    },
    data: () => {
        return {
            svgFill: '#979797',
        };
    },
    components: {
        IconBase,
        IconPDF,
        IconCSV,
    },
    methods: {
        download() {
            if (this.disabled) {
                return;
            }
            this.$emit('onDownload', this.type);
            this.onDownload?.(this.type);
        },
    },
};
</script>

<style lang="scss">
.theme-dark {
    .Download {
        &:hover {
            background-color: rgba(white, 0.1);
        }
        svg {
            fill: rgba(#fff, 0.75);
        }
    }
}
.Download {
    color: var(--color-text-black);
    font-weight: 700;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 16px;
    border-radius: 5px;
    border: solid 1px var(--color-text-inactive);

    &.is-small {
        padding: 4px 8px;
        span {
            font-size: 0.75rem;
        }
    }

    &:hover {
        background-color: rgba(0, 0, 0, 0.1);
        border: solid 1px var(--color-text-inactive);

        span {
            color: var(--color-text-black);
        }

        svg {
            fill: var(--color-text-black);
        }
    }

    &:last-of-type {
        margin-right: 0;
    }

    &.is-disabled {
        cursor: not-allowed !important;
        user-select: none !important;
        span {
            color: #ababab !important;
        }
        svg {
            fill: #ababab !important;
        }
    }

    span {
        margin-right: 5px;
        font-size: 0.95rem;
        margin-top: 1px;
        margin-bottom: 1px;
        display: block;

        @media (max-width: breakpoint(tabletPortrait)) {
            font-size: 0.75rem;
        }
    }

    svg {
        fill: rgba(#000, 0.75);
    }
}
</style>
