var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "Download",
    class: {
      'is-disabled': _vm.disabled,
      'is-small': _vm.size === 'small'
    },
    on: {
      "click": _vm.download
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.title))]), _c('IconBase', {
    attrs: {
      "fill": _vm.svgFill,
      "stroke": "none"
    }
  }, [_vm.type === 'pdf' ? _c('IconPDF') : _c('IconCSV')], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }