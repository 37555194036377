<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        :width="width"
        :height="height"
        :fill="fill"
        :stroke="stroke"
    >
        <slot></slot>
    </svg>
</template>

<script type="text/javascript">
export default {
    props: {
        width: {
            type: [Number, String],
            default: '18px',
        },
        height: {
            type: [Number, String],
            default: '18px',
        },
        fill: {
            type: String,
            default: 'currentColor',
        },
        stroke: {
            type: String,
            default: 'currentColor',
        },
    },
};
</script>
